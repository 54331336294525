/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  // background-image: url("https://wallpapercave.com/w/wp1827444.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* General Styles */

* {
  box-sizing: border-box;
  text-decoration: none;
}

html {
  font-size: 62.5%;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}

h1 {
  font-size: 2.4rem;
}

h2 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.4rem;
}

img {
  height: auto;
  width: 100%;
}

.flex-spacer {
  width: 100%;
}

.App {
  font-family: sans-serif;
  text-align: center;
  width: 100%;
}

// .navbar {
//   align-items: center;
//   border-bottom: 1px solid rgb(221, 221, 221);
//   display: flex;
//   height: 70px;
//   justify-content: space-between;
//   padding: 0 3%;
//   width: 100%;
//   -webkit-box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.42);
//   -moz-box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.42);
//   box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.42);
// }

.dark-mode__toggle {
  background: papayawhip;
  border-radius: 50px;
  border: 1px solid black;
  height: 20px;
  position: relative;
  width: 40px;
}

.toggle {
  background: #f68819;
  border-radius: 50px;
  border: black;
  height: 18px;
  left: 0;
  position: absolute;
  transition: 0.2s;
  width: 20px;
}

.toggled {
  left: 18px;
}

.dark-mode {
  color: whitesmoke;
  background-color: #B0B7BC;
  max-height: 80.8vh;

  // .navbar {
  //   background-color: #1F2022;
  //   border: none;
  // }
}

.App {
  text-align: center;
}

.ewAejC {
  background-color: black;
  color: whitesmoke;
  border: 3px solid black;
}